<template>
  <div>
        <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
          <b-col md="12">

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  @click="openLocations()"
                  v-if="disabledOptions == false"
                  
                >
                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Locations</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left">Staircase Hand Sanitizer</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="3">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Shift"
                  class="required"

                >
                  <b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3" >

            <b-form-group
              label="Date"
              class="required"

            > 
              <b-form-datepicker
              
                v-model="date"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
                @input="getLocationData()"
                :disabled="disabledOptions"
              />

                
            </b-form-group>

          </b-col>

            <b-col md="3" >
            <b-form-group
              label="Checked By"
              class="required"

            >
              <b-form-input
                placeholder="3 to 50 characters"
                v-model="checked_by"
                autocomplete="off"
                :disabled="disabledOptions"
              />
                
            </b-form-group>
          </b-col>

            <b-col md="3">
                <b-form-group label="PO No." class="required">
                    <b-form-input placeholder="3 to 50 characters" v-model="po_no" autocomplete="off" :disabled="disabledOptions"/>
                </b-form-group>
            </b-col>

        </b-row>
    
    </b-card>
    
    <b-form @submit="formSubmit">
    

    <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
      <div v-if="site && shift != ''">
        <b-table
              responsive
              show-empty
              ref = "refUserListTable"
              class = "position-relative"
              primary-key = "id"
              empty-text = "No records found"
              :fields = "tableColumns"
              :items = "list.data"
              v-if="site != ''"
          >

          <template slot="thead-top" slot-scope="data">
            <tr>
              
              <th colspan="2"> 
                <b-form-select v-model="list.building" @change="buildingChange(list.building,i)" :disabled="(checkdisabled() && (checklistData.length > 0))">

                  <b-form-select-option value="" disabled>Select Building</b-form-select-option>
                  
                  <b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="buildingDisable(building._id)">{{building.name | capitalize}}</b-form-select-option>
                  
                  
                </b-form-select>
              </th>
              <th colspan="4">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeData(i)"
                  v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false"
                  :disabled="checkdisabled()">
                 Remove
                </b-button>

                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeDBBuilding(i,checklistData[i])"
                  v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true"
                  :disabled="checkdisabled()">
                 Remove
                </b-button>
              </th>
            </tr>
          </template>
          
          <template #cell(level)="items">
              
              <p>{{items.item.level}}</p>

              <!-- <b-form-input placeholder="" v-model="items.item.level" /> -->
         
          </template>

          <template #cell(location)="items">

            <!-- <b-form-input placeholder="" v-model="items.item.location" /> -->
            <p>{{items.item.location}}</p>
         
          </template>

        <template #cell(sanitizer_unit)="items">

        <b-form-input
            placeholder=""
            v-model="items.item.sanitizer_unit"
            type="text"
            autocomplete="off"
            @input="CalculateTotalSanitizerUnit()"
         />
         
          </template>   

          <template #cell(actual_quantity)="items">

        <b-form-input
            placeholder=""
            v-model="items.item.actual_quantity"
            type="text"
            autocomplete="off"
            @input="CalculateTotalActualQuantity()"
         />
         
          </template>    

          <template #cell(remarks)="items">

        <b-form-input
            placeholder=""
            v-model="items.item.remarks"
            autocomplete="off"
         />
         
          </template>     
          

        </b-table>
      </div>
    </b-card>

 <b-card no-body class="mb-1" >
      
        <b-table

              show-empty
              empty-text = "No records found"
              :fields = "tableColumns2"
              :items = "items2"
              ref = "totalTable"
              v-if="checklistData.length > 0 && checklistData[0].data.length > 0"
          >

          <template #cell(label)="items2">

           <p>{{items2.item.building_name}}</p>

          </template>   

         <template #cell(total_sanitizer_unit)="items2">

           <p>{{items2.item.total_sanitizer_unit}}</p>

          </template>   

          <template #cell(total_actual_quantity)="items2">

           <p>{{items2.item.total_actual_quantity}}</p>
         
          </template>   

        </b-table>

    </b-card>
    
    <b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
                          <b-row>
                      <b-col md="3">
                      </b-col>
              <b-col md="3">
                <b-form-group
                  label="Start Time"
                  class="required"
                >
                    <!-- <b-form-timepicker v-model='form.startTime' locale='de' :show-seconds='showSecond' :hour12='hour12' hourCycle="h23"/> -->
        <flat-pickr
                  v-model="startTime"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />

                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="End Time"
                  class="required"
                >
                    <!-- <b-form-timepicker v-model='form.endTime' locale='de' :show-seconds='showSecond' :hour12='hour12' hourCycle="h23"/> -->
        <flat-pickr
                  v-model="endTime"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />

                </b-form-group>
              </b-col>
              <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mr-1 float-right"
            @click="addMore"
            v-if="site != ''"
            :disabled="checkdisabled()"
          >
            Add More
          </b-button>
              </b-col>
              
            </b-row>
            <b-row>
                <b-col cols="12" class="mb-2 mt-2">
                <div class="pb-2">
                <b-form-group
                  label="Signature"
                  class="required" 
                  style="font-size:20px;color:white;text-align:center"
                />

                <center>

                   <!--<b-media class="mb-2 mt-2"-->
                     <div @click="openSignature('supervisor')" v-if="crew_signature.image == null">
                        <!--<template #aside>-->
                            <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                        <!--</template>-->
                        </div>

                        <div class="d-flex flex-wrap">
                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                        </div>

                    <!--/b-media>-->

                    <img :src="crew_signature.image" width="200px" class="rounded" height="180" v-if="crew_signature.image != null" style="background: #ffffff">
                      
                      </center>
                    <center>
                        <b-button variant="danger" class="mt-1 ml-2" v-if="crew_signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </center>
                    </div>
                </b-col>
            </b-row>

      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="goBack()"
          >
            Cancel
          </b-button>

        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="id != null && typeof $route.params.id == 'undefined'">
          Discard Draft
        </b-button>

        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
            {{ id == null ? 'Save as Draft' : 'Update Draft'}}
        </b-button>      

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            type="submit"
            @click="status = 'completed'"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
          </b-button>

                    <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

        </b-col>
      </b-row>

    </b-card>

    </b-form>    

    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
                  </b-row>

          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
            <b-col md="12">
              <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                placeholder=""
                rows="3"
                v-model="approve_remark"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal> 

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker, BTabs, BTab, BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import Bus from "../../../event-bus";
import CrewSignature from './CrewSignature.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,CrewSignature, BTabs, BTab, BBreadcrumb, DatePicker
  },
  
  data() {
    return {

      draftData:null,
      //locations:[],
      
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      
      //site:this.$route.params.site_id,
      id: null,
      site:'',
      checked_by:this.$store.getters.currentUser.full_name,
      shift:'',
      building:'',
      buildings:[],
      sites:[],
      shifts:[],
      items:[],
      items2:[{building_name: '', total_sanitizer_unit: 0, total_actual_quantity: 0}],
      status: 'draft',

      openedSignModel:null,
      submitted_date : '',
      submitted_time: '',
      showButton: false,
      startTime : '',
        endTime: '',
      crew_signature : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      checklistData:[],
      tempSign : {
          image:null,
          name:'',
      },
      supervisorSign : {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
      },
      show_default:false,
      having_default:false,
      disabledOptions:false,
      po_no:'',
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,

      tableColumns:[
        { key: 'level', label: 'Level', sortable: false , thStyle:  {width: '10%'}},
        { key: 'location', label: 'Location', sortable: false , thStyle:  {width: '35%'}},
        { key: 'sanitizer_unit', label: 'Sanitizer Unit', sortable: false , thStyle:  {width: '13%'}},

        { key: 'actual_quantity', label: 'Actual Quantity', sortable: false , thStyle:  {width: '12%'}},
        { key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '35%'}},
        /*{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},*/
      ],
      tableColumns2:[
        { key: 'label', label: '', sortable: false , thStyle:  {width: '45%'}},
        /*{ key: 'test', label: '', sortable: false , thStyle:  {width: '35%'}},*/
        { key: 'total_sanitizer_unit', label: 'Total Sanitizer Unit', sortable: false , thStyle:  {width: '13%'}},

        { key: 'total_actual_quantity', label: 'Total Actual Quantity', sortable: false , thStyle:  {width: '12%'}},
        { key: 'test2', label: '', sortable: false , thStyle:  {width: '30%'}},
      ],
      months:[
         'January', 
         'February', 
         'March', 
         'April', 
         'May', 
         'June', 
         'July ', 
         'August', 
         'September', 
         'October', 
         'November', 
         'December'
      ],
      month:moment().format('MMMM'),
      year:parseInt(moment().format('YYYY')),
      yearData:[],
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
    },
    actualForm(){
      return this.$store.dispatch(POST_API, {
        data:{
            id : this.id,
            date : this.date,
            site :this.site,
            checked_by : this.checked_by,
            shift : this.shift,
            checklistData : this.checklistData,
            status: this.status,
            crew_signature: this.crew_signature,
            startTime: this.startTime,
            endTime: this.endTime,
            po_no: this.po_no,
            remarks: this.remarks,
            submitted_date: this.submitted_date,
            submitted_time: this.submitted_time,
            form_id:this.$route.params.id,
            approve_signature: this.supervisorSign,
            approve_signature_time: this.approve_signature_time,
            approve_remark: this.approve_remark,
            role:this.$store.getters.currentUser.role,
        },
        api : '/api/submit-staircase-hand-sanitizer'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              //this.showDismissibleAlert = true;

              //window.scrollTo(0,0);
              this.errorAlertCF()
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                //this.site = this.$route.params.site_id;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
            
                }else{

                    this.site = '';
                }
                this.siteChange();

                if (data != null) {
                    window.open(data, '_blank');
                }
                localStorage.setItem('routeCFCheck','no');
                Bus.$emit('counter_update');
                // this.$router.go(-1);
                //this.$router.push({ name:'custom-forms' })
                
                if(this.$route.params.id){
                  this.$router.push({name:'staircase-hand-sanitizer-report'})
                }else{

                  this.$router.push({ name:'custom-forms' })
                }
                  
              });
          }
      });
         },

      /*submitDraft(){
      //e.preventDefault();
      
      return this.$store.dispatch(POST_API, {
        data:{
            id : this.id,
            date : this.date,
            site : this.site,
            checked_by : this.checked_by,
            checklistData : this.checklistData,
            status: this.status,
            crew_signature: this.crew_signature,
            startTime: this.startTime,
            endTime: this.endTime
            //building : this.building,
        },
        api : '/api/submit-bottle-hand-sanitizer-draft'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;

              window.scrollTo(0,0);
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                //this.site = this.$route.params.site_id;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
            
                }else{

                    this.site = '';
                }
                this.siteChange();

                if (data != null) {
                    window.open(data, '_blank');
                }
                
                //Bus.$emit('counter_update');
                this.$router.go(-1);
                  
              });
          }
      });
    },*/
    
    getLocationData(){
      localStorage.setItem('routeCFCheck','no');
    // this.shift != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');

    return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
             shift:this.shift != '' ? this.shift : null,
             date:this.date,
           },
           api: '/api/staircase-hand-sanitizer-locations-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.items2 = [];
                this.id = data.id;
                this.checklistData = data.checkListData;
                this.crew_signature = data.crew_signature;
                this.startTime = data.startTime;
                this.endTime = data.endTime;
                this.po_no = data.po_no;
                this.submitted_date = data.submitted_date;
                this.submitted_time = data.submitted_time;
              if (this.checklistData.length != 0)
              {
                this.CalculateTotalSanitizerUnit();
                this.CalculateTotalActualQuantity();
              }
                return this.checklistData;
            }
        });
    },

    getShiftData(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
   
    siteChange(){
      this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      
      this.checklistData = [];
      this.shifts = [];
      this.shift = '';

      if (this.site != '') {
        this.getShiftData();
        this.getLocationData();
        this.getBuildings();
      
        //localStorage.setItem('routeCFCheck','yes');
      }else{
        //localStorage.setItem('routeCFCheck','no');
      }
    },
    getdraftData(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.site != '' ? this.site : null,
                        date:this.date,
                    },
                    api: '/api/draft-staircase-hand-sanitizer-report-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        this.draftData = data;
                       
                        return this.draftData;
                    }
                });
            },
    buildingChange(id,i){
      this.checklistData[0].building != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');

     //this.getdraftData()
                //.then(() => {
       if (id != '') {

        return this.$store.dispatch(POST_API, {
           data:{
             building:id,
             site:this.site
           },
           api: '/api/staircase-hand-sanitizer-building-location-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                this.checklistData[i].data = data;
                if (this.checklistData[i].data.length != 0)
                {
                this.CalculateTotalSanitizerUnit();
                this.CalculateTotalActualQuantity();
                }
                return this.checklistData;
            }
        });


      }else{
        this.checklistData[i].data = [];
      }
                //this.getQuantityCount();

                  /*if (this.draftData.checkListData[i] != null && this.date == this.draftData.checked_date && this.checklistData[i].building == this.draftData.checkListData[i].building)
                      {
                        this.id = this.draftData.id;
                        this.site = this.draftData.site;
                        this.checked_by = this.draftData.checked_by;
                        this.date = this.draftData.checked_date;
                        this.shift = this.draftData.shift;
                        this.status = this.draftData.status;
                        //if (this.checklistData.length == 1){
                        this.checklistData[i].data = this.draftData.checkListData[i].data;
                        this.crew_signature = this.draftData.crew_signature;
                        this.startTime = this.draftData.startTime;
                        this.endTime = this.draftData.endTime;
                        //}
                        //else {
                          //console.log('a')
                        //this.checklistData.push(this.draftData.checkListData[1]);      
                        //}
                        //this.getQuantityCount();
                        }
                        else 
                        {
                          this.id = null;
                          this.crew_signature = {
                          image:null,
                          name:'',
                          type: 'supervisor',
                          default:'no'
                        };
                        }
                
            }
        });
        }else{
        this.checklistData[i].data = [];
      }*/
               // })
    },
    addMore(){
      
      var order = 0;

      if (this.checklistData.length > 0) {
        var lastIndex = this.checklistData.length - 1;
        order = this.checklistData[lastIndex].order + 1;
      }

      var obj = {
          building:'',
          data : [],
          order:order,
          total_sanitizer_unit:'',
          total_actual_quantity:'',
          db_building:false,
      };

      this.checklistData.push(obj);
    },
    removeData(index){
      this.checklistData.splice(index,1);
      this.items2.splice(index,1);
      if (this.checklistData.length == 0) {
        this.addMore();
      }
    },

    removeDBBuilding(index,item){
      var msg = 'Are you sure want to remove this building record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      building_id : item.building,
                      date : this.date,
                      site :this.site,
                      shift : this.shift,
                  },
                  api : "/api/remove-shs-db-building",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.checklistData.splice(index,1);
                        if (this.checklistData.length == 0) {
                          this.addMore();
                        }

                        var temp = [];

                        this.checklistData.forEach((ele, ind) => {
                          if(ele.building_name == this.items2[ind].building_name){
                            temp.push(this.items2[ind])
                          }
                        });
                        this.items2 = temp;
                        this.$refs.totalTable.refresh();
                          
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },

    getBuildings(){
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                site:this.site,
                form:'Staircase Hand Sanitizer'
            },
            api: '/api/all-buildings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.buildings = this.$store.getters.getResults.data;            
                return this.buildings;
            }
        });
    },
    openLocations(){
      /*this.$router.push({ name: 'hand-sanitizer-buildings',params:{site_id:this.$route.params.site_id,id:'all-building'}})*/
      this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-sites',id:'all-building',form:'Staircase_Hand_Sanitizer'}})
    },

    refreshData(){
                /*if(this.flag){
                    
                }*/

                //this.form.site = '';
                this.flag = false;
                this.id = null;
                this.site = '';

                this.checked_by = this.$store.getters.currentUser.full_name;
                this.date = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
                this.shift = '';
                this.status = 'completed';
                this.checklistData = [];

                this.crew_signature = {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                };
                this.startTime = '';
                this.endTime = '';
                this.po_no = '';
                
                this.openedSignModel=null;
            },
      discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-status-staircase-hand-sanitizer-report-data",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                /*this.successAlert()
                                .then(() => {
                                    this.flag = true;
                                    this.refreshData();
                                });*/
                                localStorage.setItem('routeCFCheck','no');
                                this.successAlert()
                                .then(() => {
                                    
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
    /*getQuantityCount(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.site != '' ? this.site : null,
                    },
                    api: '/api/get-quantity-count'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                      if(data){
                          for (var i = 0; i < this.checklistData.length; i++)
                          {
                            for (var j = 0; j < data.checkListData.length; j++)
                            {
                              if (this.checklistData[i].building == data.checkListData[j].building)
                              {
                                for (var k = 0; k < this.checklistData[i].data.length; k++)
                                {
                                  for (var l = 0; l < data.checkListData[j].data.length; l++)
                                  {
                                    if (this.checklistData[i].data[k].location == data.checkListData[j].data[l].location)
                                    {
                                      this.checklistData[i].data[k].quantity = data.checkListData[j].data[l].quantity;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                });
     },*/
     /*setDefaultCheckBox(){
          for (var i = 0; i < this.checklistData.length; i++)
          {
          for (var j = 0; j < this.checklistData[i].data.length; j++)
          {
          this.checklistData[i].data[j].ok = 'yes';
          this.checklistData[i].data[j].three_fourth = 'yes';
          }
     }
  },*/
supervisorImageUpload(event){
        var input = event.target;
        var files = event.target.files

        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];

            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .jpg, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                });

                this.$refs.supervisorSInput.value=null;

            } else if (image.size > 2097152) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$refs.supervisorSInput.value=null;
            } else {
                reader.onload = (e) => {
                    this.crew_signature.image = e.target.result;
                    this.crew_signature.name = image.name;
                    this.crew_signature.default = 'no';
                }
            }
        }
    },
    removeSign(sign){
        this.crew_signature.image = null;
        this.crew_signature.name = '';
        this.crew_signature.default = 'no';
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'signature.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      this.$refs['supervisorSInput'].click();
      this.$refs['signatureModel'].hide();

    },
    openSignature(type){

      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
      
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{
        if (this.openedSignModel == 'supervisor') {

            this.crew_signature.image = data;
            this.crew_signature.name = 'signature.png';
            this.crew_signature.type = 'supervisor';
            this.crew_signature.default = 'no';
            this.$refs['signatureModel'].hide();
        }else{
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'signature.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';
            this.status = 'approved';
            this.actualForm();
        }
      }
    },
    saveDefault(){

      if (this.openedSignModel == 'supervisor') {
          this.crew_signature = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes'
          }
        }else{

          this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes',
            type:'supervisor'
          }
          this.status = 'approved';
          this.actualForm();

        }
                 
      
        
      this.$refs['signatureModel'].hide();
    },
  breadCrumb(){
          if(this.$route.params.id){

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Reports',
        },{
          to:{name:'custom-forms-dashboard'},
          text: 'Form Dashboard',
        },{
          to:{name:'custom-forms-reports'},
          text:'Custom Forms'
        },{
          to:{name:'staircase-hand-sanitizer-report'},
          text:'Staircase Hand Sanitizer Report'
        },{
          to:null,
          text:'Edit',
          active:true
        }];
        return this.filterReportBreadCrum(item);

      }else{
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Staircase Hand Sanitizer',   
        active:true             
      }];
      }
      return item;
    },
    CalculateTotalSanitizerUnit(){
      for (var i = 0; i < this.checklistData.length; i++)
      {
      if(this.checklistData.length != this.items2.length)
      {
                var obj = {
                building_name: '',
                total_sanitizer_unit: 0,
                total_actual_quantity: 0,                                               
      };
        this.items2.push(obj);
      }
      this.items2[i].total_sanitizer_unit = 0;
      for (var j = 0; j < this.checklistData[i].data.length; j++)
      {
        this.items2[i].building_name = this.checklistData[i].data[j].building_name;
        if (this.checklistData[i].data[j].sanitizer_unit != null && this.checklistData[i].data[j].sanitizer_unit != '' && !isNaN(this.checklistData[i].data[j].sanitizer_unit))
        {
        this.items2[i].total_sanitizer_unit = this.items2[i].total_sanitizer_unit + parseInt(this.checklistData[i].data[j].sanitizer_unit)
        this.checklistData[i].total_sanitizer_unit = this.items2[i].total_sanitizer_unit;
        }
      }
      }
        //this.total_sanitizer_unit = this.items2[0].total_sanitizer_unit;
    },
      CalculateTotalActualQuantity(){

      for (var i = 0; i < this.checklistData.length; i++)
      {
      
      this.items2[i].total_actual_quantity = 0;

      for (var j = 0; j < this.checklistData[i].data.length; j++)
      {
        if (this.checklistData[i].data[j].actual_quantity != null && this.checklistData[i].data[j].actual_quantity != '' && !isNaN(this.checklistData[i].data[j].actual_quantity))
        {
        this.items2[i].total_actual_quantity = this.items2[i].total_actual_quantity + parseInt(this.checklistData[i].data[j].actual_quantity)
        this.checklistData[i].total_actual_quantity = this.items2[i].total_actual_quantity;
        }
      }
      }
        //this.total_actual_quantity = this.items2[0].total_actual_quantity;
    },
      buildingDisable(id){
      var building_ids = [];
    
      for (var i = 0; i < this.checklistData.length; i++) {
        if (this.checklistData[i].building != '') {
          building_ids.push(this.checklistData[i].building);
        }
      }


      if (building_ids.indexOf(id) >= 0) {
        return true;
      }else{
        return false;
      }

    },
      goBack(){

      var msg = 'Do you want to Go back without saving your report?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
            localStorage.setItem('routeCFCheck','no')
            // this.$router.go(-1);
            if(this.$route.params.id){
              this.$router.push({name:'staircase-hand-sanitizer-report'})
            }else{

              this.$router.push({ name:'custom-forms' })
            }
              

          }
      })
    },
      getFormDetails(){
      /*localStorage.setItem('routeCFCheck','yes');*/

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/get-staircase-hand-sanitizer-report-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.id  = data._id;
                this.date  = data.checked_date;
                this.site  = data.site;
                this.checked_by  = data.checked_by;
                this.shift  = data.shift;
                this.checklistData  = data.checkListData;
                this.status = data.status;
                this.crew_signature = data.crew_signature;
                this.startTime = data.startTime;
                this.endTime = data.endTime;
                this.po_no = data.po_no;

                var role = this.$store.getters.currentUser.role;

                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                    //this.disabledOptions = false;
                    this.ledit = true;
                } else if(((role == 'admin') || (role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                    this.ledit = true;
                }

                this.getShiftData();
                this.getBuildings();
                this.CalculateTotalSanitizerUnit();
                this.CalculateTotalActualQuantity();
            }
        });
    },
    disabledDate(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },
    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    }
  },
  mounted(){
    this.useDefault();
    this.allSites();
    this.siteChange();
    //this.getLocationData();
    // to add crew sign
            Bus.$on('update-imagecrew', (data) => {
        this.crew_signature = data.data;
    })

    // to delete crew sign
    Bus.$on('remove-imagecrew', (data) => {
        this.crew_signature = data.data = {
            default:'no',
            name:'',
            image:null,
        };
    })

      if(this.$route.params.id){
      this.disabledOptions = true;
      this.getFormDetails();
    }
      }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>